import { initAccordion } from '../components/initAccordion';
import { initAutocompleteDevices } from '../components/initAutocompleteDevices';
import { initTabs } from '../components/initTabs';
import { initDeclineModal } from '../components/modal/initDeclineModal';
import { initDefaultGlideSlider } from '../components/sliders/initDefaultGlideSlider';

document.addEventListener('DOMContentLoaded', function () {
    initAccordion();
    initDefaultGlideSlider();
    initAutocompleteDevices();
    initTabs();
    initDeclineModal();
});
