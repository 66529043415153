import Glide from '@glidejs/glide';

export const initDefaultGlideSlider = () => {
    const sliderSelector = '[data-default-glide-slider]';
    const slider = document.querySelector(`${sliderSelector}`);
    if (!slider) return;

    const glideSlider = new Glide(`${sliderSelector}`, {
        type: 'carousel',
        startAt: 0,
        perView: 1.2,
        hoverpause: true,
        focusAt: 0,
    });

    glideSlider.mount();
};
