export const initTabs = () => {
    // VARS
    const tabToggles = document.querySelectorAll('[data-tab-nav-item]');
    if (!tabToggles.length) return;
    const classActive = 'active';

    // LISTENERS
    [...tabToggles].forEach((tabToggle) => {
        tabToggle.addEventListener('click', handleOnClickTabNavItem, false);
    });

    // HANDLERS
    function handleOnClickTabNavItem() {
        if (this.classList.contains(classActive)) return;
        toggleTab(this);
    }

    // FUNCTIONS
    function toggleTab(tabNavItem) {
        const tab = tabNavItem.closest('[data-tab]');
        const indexNavItem = tabNavItem.dataset.tabNavItem;

        toggleNav(tab, indexNavItem);
        toggleTabContent(tab, indexNavItem);
    }

    function toggleNav(tab, indexNavItem) {
        const oldTabToggle = tab.querySelector(`.${classActive}[data-tab-nav-item]`);
        const currentTabToggles = tab.querySelector(`[data-tab-nav-item="${indexNavItem}"]`);

        removeStyleOldTabNavItem(oldTabToggle);
        setStyleCurrentTabNavItem(currentTabToggles);
    }

    function toggleTabContent(tab, indexNavItem) {
        const oldTabContent = tab.querySelector(`.${classActive}[data-tab-content]`);
        const currentTabContent = tab.querySelector(`[data-tab-content="${indexNavItem}"]`);

        oldTabContent.classList.remove(classActive);
        currentTabContent.classList.add(classActive);
    }

    function removeStyleOldTabNavItem(oldTabToggle) {
        oldTabToggle.classList.remove(classActive);
        oldTabToggle.closest('[data-tab-nav-item-wrap]').classList.remove(classActive);
    }

    function setStyleCurrentTabNavItem(currentTabToggles) {
        currentTabToggles.classList.add(classActive);
        currentTabToggles.closest('[data-tab-nav-item-wrap]').classList.add(classActive);
    }
};
