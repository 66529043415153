import { removeChildElements } from '../helper-function/removeChildElements';

export const initAutocompleteDevices = () => {
    // VARS
    const autocompleteForms = document.querySelectorAll('[data-autocomplete-device-form]');
    if (!autocompleteForms.length) return;
    let autocompleteDevices;
    const hiddenClass = '--hidden';
    const errorClass = '--error';
    const classSelected = '--selected';
    let timeoutInputEventId;
    let timeoutRespondId;
    let selectedIndex = -1;
    const debouncedHandleOnFocusInput = debounce(handleOnInput, 500);

    // LISTENERS
    autocompleteForms.forEach((form) => {
        const checkNewBtn = form.querySelector('[data-autocomplete-device-check-new]');
        const input = form.querySelector('[data-autocomplete-device-input]');

        form.addEventListener('submit', handleOnSubmitForm);
        checkNewBtn.addEventListener('click', handleOnClickCheckNewBtn);

        input.addEventListener('focus', handleOnFocusInput);
        input.addEventListener('input', debouncedHandleOnFocusInput);
        input.addEventListener('keydown', handleOnKeydown);
        input.addEventListener('blur', handleOnBlurInput);
    });

    // HANDLERS
    function handleOnSubmitForm(event) {
        event.preventDefault();
        const input = this.querySelector('[data-autocomplete-device-input]');

        if (input.value.trim()) {
            renderCheckDeviceMessage(input);
        } else {
            showError(input);
        }
    }

    function handleOnFocusInput() {
        const form = this.closest('[data-autocomplete-device-form]');

        hideError(this);

        if (!autocompleteDevices) {
            fetchDevices(form);
        }
    }

    function handleOnInput() {
        if (document.activeElement === this) {
            if (this.value.trim()) {
                renderDropdown(this);
            } else {
                const form = this.closest('[data-autocomplete-device-form]');
                const dropdown = form.querySelector('[data-autocomplete-device-dropdown]');
                hideDropdown(dropdown);
            }
        }
    }

    function handleOnBlurInput() {
        const form = this.closest('[data-autocomplete-device-form]');
        const dropdown = form.querySelector('[data-autocomplete-device-dropdown]');
        hideDropdown(dropdown);
    }

    function handleOnKeydown(event) {
        navigateDropdown(this, event);
    }

    function handleOnClickCheckNewBtn() {
        const form = this.closest('[data-autocomplete-device-form]');
        const input = form.querySelector('[data-autocomplete-device-input]');
        const formRow = form.querySelector('[data-autocomplete-device-row="form"]');
        const responseRow = form.querySelector('[data-autocomplete-device-row="response"]');

        hideError(input);
        form.reset();
        formRow.classList.remove(hiddenClass);
        responseRow.classList.add(hiddenClass);
    }

    document.addEventListener('mousedown', function (event) {
        if (event.target.closest('[data-autocomplete-device-dropdown-item]')) {
            const item = event.target.closest('[data-autocomplete-device-dropdown-item]');
            const form = item.closest('[data-autocomplete-device-form]');
            const input = form.querySelector('[data-autocomplete-device-input]');
            input.value = item.textContent;
        }
    });

    // FUNCTIONS
    async function fetchDevices(form) {
        const source = await fetch(form.action);
        autocompleteDevices = await source.json();
    }

    function renderDropdown(input) {
        const form = input.closest('[data-autocomplete-device-form]');
        const dropdown = form.querySelector('[data-autocomplete-device-dropdown]');

        selectedIndex = -1;
        hideDevicesList(dropdown);
        hideNoResultMessage(dropdown);
        showDropdownSpinner(dropdown);
        showDropdown(dropdown);

        timeoutRespondId = setInterval(function () {
            if (typeof autocompleteDevices !== 'undefined') {
                const deviceList = getDeviceList(input.value.trim());

                clearInterval(timeoutRespondId);
                hideDropdownSpinner(dropdown);

                if (deviceList.length) {
                    renderDevicesList(dropdown, deviceList);
                    showDevicesList(dropdown);
                } else {
                    showNoResultMessage(dropdown);
                }
            }
        }, 300);
    }

    function showError(input) {
        const label = input.closest('[data-autocomplete-device-label]');
        const error = label.querySelector('[data-autocomplete-device-error]');
        error.classList.remove(hiddenClass);
        input.classList.add(errorClass);
    }

    function hideError(input) {
        const label = input.closest('[data-autocomplete-device-label]');
        const error = label.querySelector('[data-autocomplete-device-error]');
        error.classList.add(hiddenClass);
        input.classList.remove(errorClass);
    }

    function debounce(func, delay) {
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(timeoutInputEventId);
            timeoutInputEventId = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    }

    function showDropdown(dropdown) {
        dropdown.classList.remove(hiddenClass);
    }

    function hideDropdown(dropdown) {
        dropdown.classList.add(hiddenClass);
        clearDropdownList(dropdown);
    }

    function showDropdownSpinner(dropdown) {
        const spinner = dropdown.querySelector('[data-autocomplete-device-dropdown-spinner]');
        spinner.classList.remove(hiddenClass);
    }

    function hideDropdownSpinner(dropdown) {
        const spinner = dropdown.querySelector('[data-autocomplete-device-dropdown-spinner]');
        spinner.classList.add(hiddenClass);
    }

    function showNoResultMessage(dropdown) {
        const noResultMessage = dropdown.querySelector('[data-autocomplete-device-dropdown-no-result]');
        noResultMessage.classList.remove(hiddenClass);
    }

    function hideNoResultMessage(dropdown) {
        const noResultMessage = dropdown.querySelector('[data-autocomplete-device-dropdown-no-result]');
        noResultMessage.classList.add(hiddenClass);
    }

    function clearDropdownList(dropdown) {
        const list = dropdown.querySelector('[data-autocomplete-device-dropdown-list]');
        removeChildElements(list);
    }

    function getDeviceList(searchQuery) {
        const searchRegex = new RegExp(searchQuery, 'i');

        return autocompleteDevices
            .filter((item) => searchRegex.test(item.searchString))
            .slice(0, 100)
            .map((item) => {
                const highlightedText = item.searchString.replace(searchRegex, '<b>$&</b>');
                return `<li class="autocomplete-device__dropdown-item" data-autocomplete-device-dropdown-item>${highlightedText}</li>`;
            });
    }

    function renderDevicesList(dropdown, deviceList) {
        const list = dropdown.querySelector('[data-autocomplete-device-dropdown-list]');

        clearDropdownList(dropdown);

        deviceList.forEach((device) => {
            list.insertAdjacentHTML('beforeend', device);
        });
    }

    function showDevicesList(dropdown) {
        const list = dropdown.querySelector('[data-autocomplete-device-dropdown-list]');
        list.classList.remove(hiddenClass);
    }

    function hideDevicesList(dropdown) {
        const list = dropdown.querySelector('[data-autocomplete-device-dropdown-list]');
        list.classList.add(hiddenClass);
    }

    function navigateDropdown(input, event) {
        const form = input.closest('[data-autocomplete-device-form]');
        const dropdown = form.querySelector('[data-autocomplete-device-dropdown]');
        const list = dropdown.querySelector('[data-autocomplete-device-dropdown-list]');
        const items = list.querySelectorAll('[data-autocomplete-device-dropdown-item]');

        if (!list.classList.contains(hiddenClass)) {
            if (event.key === 'ArrowDown') {
                event.preventDefault();
                clearSelection(items);
                selectedIndex = selectedIndex < items.length - 1 ? selectedIndex + 1 : 0;
                items[selectedIndex].classList.add(classSelected);

                dropdown.scrollTo({
                    top: items[selectedIndex].offsetTop,
                    behavior: 'smooth',
                });
            } else if (event.key === 'ArrowUp') {
                event.preventDefault();
                clearSelection(items);
                selectedIndex = selectedIndex > 0 ? selectedIndex - 1 : items.length - 1;
                items[selectedIndex].classList.add(classSelected);

                dropdown.scrollTo({
                    top: items[selectedIndex].offsetTop,
                    behavior: 'smooth',
                });
            } else if (event.key === 'Enter') {
                const selectedItem = list.querySelector(`.${classSelected}`);

                if (selectedItem) {
                    event.preventDefault();
                    clearSelection(items);
                    input.value = items[selectedIndex].textContent;
                    selectedIndex = -1;
                    input.blur();
                    input.focus();
                }
            }
        }
    }

    function clearSelection(items) {
        [...items].forEach((item) => {
            item.classList.remove(classSelected);
        });
    }

    function renderCheckDeviceMessage(input) {
        if (typeof autocompleteDevices !== 'undefined') {
            const searchQuery = input.value.trim().toLowerCase();
            const filteredValues = autocompleteDevices.filter(
                (item) => item.searchString.toLowerCase() === searchQuery,
            );
            const form = input.closest('[data-autocomplete-device-form]');
            const formRow = form.querySelector('[data-autocomplete-device-row="form"]');
            const responseRow = form.querySelector('[data-autocomplete-device-row="response"]');
            const responseSuccess = responseRow.querySelector('[data-autocomplete-device-respond="success"]');
            const responseError = responseRow.querySelector('[data-autocomplete-device-respond="error"]');

            formRow.classList.add(hiddenClass);
            responseRow.classList.remove(hiddenClass);

            if (filteredValues.length) {
                responseError.classList.add(hiddenClass);
                responseSuccess.classList.remove(hiddenClass);
            } else {
                responseError.classList.remove(hiddenClass);
                responseSuccess.classList.add(hiddenClass);
            }
        }
    }
};
